@import 'https://fonts.googleapis.com/css?family=Inter:400,500,600,700';
/*--------------------
General Style
---------------------*/
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

body,
html {
  /*height: var(--tg-viewport-stable-height);*/
  height: 100vh;

  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  height: 100%;
  background: white;
  overscroll-behavior: none none!important;
  transform: translateZ(0);
  -webkit-overflow-scrolling: touch;
}

#root {
  height: 100%;
}