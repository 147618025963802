.booking {
    vertical-align: top;
    cursor: pointer;
    transition: 0.5s;
}

.booking .room-pic {
    width: -webkit-calc(100% - 10px);
    width:    -moz-calc(100% - 10px);
    width:         calc(100% - 10px);

    height: -webkit-calc(40vh - 120px);
    height: -moz-calc(40vh - 120px);
    height: calc(40vh - 120px);

    object-fit: cover;
    border-radius: 8px;
    box-shadow: 4px 6px 4px rgba(0, 0, 0, 0.25);
}

.booking-text {
    white-space: normal;
}

.booking-title {
    white-space: normal;
}

.booking:hover .room-pic {
    box-shadow: 6px 8px 6px rgba(0, 0, 0, 0.5);
}

.booking:active .room-pic {
    box-shadow: 6px 8px 6px rgba(0, 0, 0, 0.5);
}

.booking:hover .booking-text {
    color: #BB2649;
}

.booking:active .booking-text {
    color: #BB2649;
}

.booking:hover .booking-title {
    color: #BB2649;
}

.booking:active .booking-title {
    color: #BB2649;
}


@media (max-height: 650px) {

    .booking {
        border: 1px solid #BB2649;
        border-radius: 7px;
        margin-bottom: 16px;
    }

    .booking-description {
        display: inline-block;
        width: calc(100% - 70px);
        text-align: left;
        vertical-align: middle;
        padding-bottom: 0;
        padding-top: 0;
    }

    .booking .booking-title {
        white-space: nowrap;
        overflow-x: hidden;
        padding-bottom: 0;
        line-height: 12px;
    }

    .booking .room-pic {
        border-radius: 0;
        box-shadow: 0 0 0;
        vertical-align: middle;
        height: 25px;
        width: auto;
        margin: 12px 6px 12px 12px;
    }

    .booking:hover .room-pic {
        box-shadow: 0 0;
    }

    .booking:active .room-pic {
        box-shadow: 0 0;
    }

}