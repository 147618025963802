.scroll-content-number {
    height: 42px;
    padding: 9px;
    scroll-snap-align: end;
    scroll-snap-stop: always;
    cursor: default;
}

.scroll-content-number__selected {
    /*opacity: 1;*/
    /*font-size: 25px;*/
}