.my-booking-description-screen {
    height: 100%;
    width: 100%;
    position: fixed;
}

.my-booking-description-container {
    /*left: 0;*/
    /*bottom: 0;*/
    /*top: 100px;*/
    height: calc(100% - 100px);
    left: 0;
    top: 0;

    margin-top: 100px;
    padding-left: 16px;
    padding-right: 16px;

}

.my-booking-description-content {
    height: 75%;
    overflow-y: auto;
}

.my-booking-description-title {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;

    color: #7B7E7A;
}

.my-booking-description-title-time {
    padding-left: 10px;

    font-style: italic;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;

    color: #7B7E7A;
}

.my-booking-description-text {

    font-style: italic;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;

    color: #000000;
}

.my-booking-description-text > div {
    padding-top: 6px;
    padding-bottom: 32px;
    text-transform: none;
}

.buttons-footer {
    height: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.buttons-footer .button {
    margin-bottom: 5px;
    margin-top: 5px;
}
