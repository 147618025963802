.your-bookings {
    padding-top: 20px;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;

    color: #7B7E7A;
}

.your-bookings .title, .your-bookings .count {
    text-transform: uppercase;
    display: inline-block;
    text-align: left;
    width: 100%;
    margin-right: -100%;
    vertical-align: bottom;
}

.your-bookings .count {
    font-style: italic;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;

    text-align: right;
}

.bookings {
    padding: 20px;
    /* padding-left: 200px; */
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
}

.bookings::-webkit-scrollbar {
    display: none;
}

.booking-description {
    padding-top: 12px;
}

.bookings .booking-title {
    padding-bottom: 6px;
    color: #000000;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
}

.bookings .booking-text {
    color: #000000;
    font-style: italic;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
}

@media (max-height: 550px) {
    .your-bookings {
        padding-top: 12px;
    }
    .bookings {
        padding-top: 12px;
    }

}