
.confirmation-modal-main {
    width: 80%;
    height: 180px;

    background-color: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-shadow: 4px 6px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
}

.confirmation-modal-title {
    height: 65%;
    display: flex;
    padding-top: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #000000;
}

.confirmation-modal-buttons {
    height: 35%;

    margin-left: 50%;
    margin-right: 10px;
    display: flex;
    align-items: center;

}

.confirmation-modal-buttons button {
    width: 35%;
}