.found-rooms-screen {
    height: 100%;
    width: 100%;
    position: fixed;
    /*opacity: 50%;*/
    /*background-clip: content-box !important;*/
    /*transition: left 5s cubic-bezier(0, 0, 1, 1);*/
}

.found-rooms-relevant {
    padding-bottom: 25px;
}

.found-rooms-list {
    height: calc(100% - 100px);
    left: 0;
    top: 0;

    margin-top: 100px;
    padding-left: 16px;
    padding-right: 16px;
    overflow-y: auto;
}

.found-rooms-title {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;

    color: #7B7E7A;
}

.found-rooms-title-time {
    padding-left: 10px;

    font-style: italic;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;

    color: #7B7E7A;
}

.found-rooms-subtitle {
    padding-top: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;

    color: #BB2649;
}

.hand-icon {
    position: absolute;
    animation: go-left-right 1.5s infinite alternate;
    right: 10%;
    top: 50%;
}


@keyframes go-left-right {
    from {
        transform-origin: bottom center;
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        transform-origin: bottom center;
        -ms-transform: rotate(-15deg);
        -moz-transform: rotate(-15deg);
        -webkit-transform: rotate(-15deg);
        -o-transform: rotate(-15deg);
        transform: rotate(-15deg);
    }
}