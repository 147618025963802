.date-container {
    height: calc(100% - 100px);
    width: 100%;
    left: 0;
    top: 0;
    margin-top: 100px;
    position: fixed;
    background: white;
    z-index: 1;
}

.date-container .date-calendar {
    margin-top: 65px;
    height: calc(85% - 65px);
    width: 100%;
    padding-left: 26px;
    padding-right: 26px;
    overflow-y: auto;
    /*-webkit-overflow-scrolling: touch; !* enables “momentum” (smooth) scrolling *!*/
}


.date-container .button-footer {
    height: 15%;
    padding: 0 26px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dayss-of-week {
    width: 100%;
    text-align: justify-all;
}









@charset "UTF-8";
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
    border-color: #ccc;
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: "";
    display: block;
    height: 9px;
    position: absolute;
    top: 6px;
    width: 9px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
    margin-left: -4px;
    position: absolute;
    width: 0;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px;
    content: "";
    z-index: -1;
    border-width: 8px;
    left: -8px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
    border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
    top: 0;
    margin-top: -8px;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-top: none;
    border-bottom-color: #f0f0f0;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    top: 0;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
    bottom: 0;
    margin-bottom: -8px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
    border-bottom: none;
    border-top-color: #fff;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
    bottom: 0;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
    bottom: -1px;
    border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 100%;
}

.react-datepicker {
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    display: inline-block;
    position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
    left: 35px;
}
.react-datepicker--time-only .react-datepicker__time-container {
    border-left: 0;
}
.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}

.react-datepicker__triangle {
    position: absolute;
    left: 50px;
}

.react-datepicker-popper {
    z-index: 1;
}
.react-datepicker-popper[data-placement^=bottom] {
    padding-top: 10px;
}
.react-datepicker-popper[data-placement=bottom-end] .react-datepicker__triangle, .react-datepicker-popper[data-placement=top-end] .react-datepicker__triangle {
    left: auto;
    right: 50px;
}
.react-datepicker-popper[data-placement^=top] {
    padding-bottom: 10px;
}
.react-datepicker-popper[data-placement^=right] {
    padding-left: 8px;
}
.react-datepicker-popper[data-placement^=right] .react-datepicker__triangle {
    left: auto;
    right: 42px;
}
.react-datepicker-popper[data-placement^=left] {
    padding-right: 8px;
}
.react-datepicker-popper[data-placement^=left] .react-datepicker__triangle {
    left: 42px;
    right: auto;
}

.react-datepicker__header {
    padding: 8px 0;
    position: relative;
}
.react-datepicker__header--time {
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px;
}
.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
    border-top-left-radius: 0;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: 0.3rem;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
    display: inline-block;
    margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    margin-top: 0;
    color: #000;
    font-weight: 700;
    font-size: 14px;
    text-transform: capitalize;
    text-align: left;
}

.react-datepicker-time__header {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.react-datepicker__navigation {
    align-items: center;
    background: none;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 2px;
    padding: 0;
    border: none;
    z-index: 1;
    height: 32px;
    width: 32px;
    text-indent: -999em;
    overflow: hidden;
}
.react-datepicker__navigation--previous {
    left: 2px;
}
.react-datepicker__navigation--next {
    right: 2px;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 85px;
}
.react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.react-datepicker__navigation--years-previous {
    top: 4px;
}
.react-datepicker__navigation--years-upcoming {
    top: -4px;
}
.react-datepicker__navigation:hover *::before {
    border-color: #a6a6a6;
}

.react-datepicker__navigation-icon {
    position: relative;
    top: -1px;
    font-size: 20px;
    width: 0;
}
.react-datepicker__navigation-icon--next {
    left: -2px;
}
.react-datepicker__navigation-icon--next::before {
    transform: rotate(45deg);
    left: -7px;
}
.react-datepicker__navigation-icon--previous {
    right: -2px;
}
.react-datepicker__navigation-icon--previous::before {
    transform: rotate(225deg);
    right: -7px;
}

.react-datepicker__month-container {
    width: 100%;
    float: left;
}

.react-datepicker__year {
    margin: 0.4rem;
    text-align: center;
}
.react-datepicker__year-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 180px;
}
.react-datepicker__year .react-datepicker__year-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
}

.react-datepicker__month {
    text-align: center;
}
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
}


.react-datepicker__day-names,
.react-datepicker__week {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    white-space: nowrap;
}

.react-datepicker__day-names {
    visibility: hidden;
    margin-bottom: -32px;

}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: #000;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
}


.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
    cursor: pointer;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
    border-radius: 0.3rem;
    background-color: #f0f0f0;
}
.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
    font-weight: bold;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    border-radius: 5px;
    background-color: #BFCAD6;
    color: #000;
}
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
    background-color: #BB2649;
    color: #fff;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    border-radius: 0.3rem;
    background-color: #BB2649;
    color: #fff;
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
    background-color: #BB2649;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
    cursor: default;
    color: #ccc;
}
.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
    background-color: transparent;
}

.react-datepicker__day--outside-month {
    visibility: hidden;
}


@media (max-width: 400px), (max-height: 550px) {
    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .react-datepicker__time-name {
        width: 2rem;
        line-height: 2rem;
    }
}
.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
    font-size: 1.44rem;
}

