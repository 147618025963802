.room-description-screen {
    height: 100%;
    width: 100%;
    position: fixed;
}

.room-description-container {
    /*left: 0;*/
    /*bottom: 0;*/
    /*top: 100px;*/
    height: calc(100% - 100px);
    left: 0;
    top: 0;

    margin-top: 100px;
    padding-left: 16px;
    padding-right: 16px;

}

.room-description-content {
    height: 85%;
    overflow-y: auto;
}

.room-description-text {

    font-style: italic;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;

    color: #000000;
}

.room-description-text > div {
    padding-top: 6px;
    padding-bottom: 32px;
    text-transform: none;
}

.button-footer {
    height: 15%;
    display: flex;
    justify-content: center;
    margin: auto;
}

