.default-modal-bg {
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}

.default-modal-main {
    width: 90%;
    height: 35%;

    background-color: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-shadow: 4px 6px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
}

.default-modal-title {
    margin-left: 20px;
    margin-right: 20px;
    height: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    white-space: pre-line;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #000000;
}

.default-modal-button {
    display: flex;
    align-items: center;

    height: 35%;
}