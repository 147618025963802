.booking-management-screen {
    height: 100%;
    overflow-y: hidden;
}

.booking-rooms-browse-container {
    height: 100%;
    padding-top: 100px;
    padding-left: 16px;
    padding-right: 16px;
}

.booking-rooms-browse-content-wrapper {
    height: 85%;
}

.booking-management-date-picker {
    display: flex;
    justify-content: flex-end;
    font-style: italic;
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
    text-transform: uppercase;
}

.booking-management-date-picker svg {
    margin-left:7px;
    margin-right: 4px;
    cursor: pointer;
}

.booking-management-main-table {
    overflow-x: auto;
    overflow-y: auto;
    height: 100%;
}

.booking-management-timeline-wrapper {
    width: 700px;
    height: 100%;
}

.booking-rooms-browse-container .button-footer {
    height: 15%;
    align-items: center;
}


.rbc-calendar {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

.rbc-abs-full, .rbc-row-bg {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.rbc-ellipsis, .rbc-show-more, .rbc-row-segment .rbc-event-content, .rbc-event-label {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.rbc-rtl {
    direction: rtl;
}

.rbc-off-range {
    color: #999999;
}

.rbc-off-range-bg {
    background: #e6e6e6;
}

.rbc-header {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    padding: 0 3px;
    text-align: center;
    vertical-align: middle;
    font-size: 11px;
    min-height: 0;
}
.rbc-header + .rbc-header {
    border-left: 1px solid #ddd;
}
.rbc-rtl .rbc-header + .rbc-header {
    border-left-width: 0;
    border-right: 1px solid #ddd;
}
.rbc-header > a, .rbc-header > a:active, .rbc-header > a:visited {
    color: inherit;
    text-decoration: none;
}

.rbc-row-content {
    position: relative;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none;
    z-index: 4;
}

.rbc-row-content-scrollable {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
}
.rbc-row-content-scrollable .rbc-row-content-scroll-container {
    height: 100%;
    overflow-y: scroll;
    /* Hide scrollbar for Chrome, Safari and Opera */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.rbc-row-content-scrollable .rbc-row-content-scroll-container::-webkit-scrollbar {
    display: none;
}

.rbc-today {
    background-color: #fff;
}

.rbc-event, .rbc-day-slot .rbc-background-event {
    border: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 2px 2px;
    background-color: #c2eff6;
    border-radius: 5px;
    color: #000;
    text-align: left;
    margin: 0;
    width: 100%;
}
.rbc-slot-selecting .rbc-event, .rbc-slot-selecting .rbc-day-slot .rbc-background-event, .rbc-day-slot .rbc-slot-selecting .rbc-background-event {
    cursor: inherit;
    pointer-events: none;
}
.rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event {
    background-color: #265985;
}
.rbc-event:focus, .rbc-day-slot .rbc-background-event:focus {
    outline: 5px auto #3b99fc;
}

.rbc-event-label {
    font-size: 8px;
    font-weight: bold;
}

.rbc-event-overlaps {
    -webkit-box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
    box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
}

.rbc-event-continues-prior {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rbc-event-continues-after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rbc-event-continues-earlier {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.rbc-event-continues-later {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.rbc-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.rbc-row-segment {
    padding: 0 1px 1px 1px;
}
.rbc-selected-cell {
    background-color: rgba(0, 0, 0, 0.1);
}

.rbc-show-more {
    background-color: rgba(255, 255, 255, 0.3);
    z-index: 4;
    font-weight: bold;
    font-size: 85%;
    height: auto;
    line-height: normal;
    color: #3174ad;
}
.rbc-show-more:hover, .rbc-show-more:focus {
    color: #265985;
}

.rbc-time-column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100%;
}
.rbc-time-column .rbc-timeslot-group {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.rbc-timeslot-group {
    border-bottom: 1px solid #ddd;
    min-height: 65px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
}

.rbc-time-gutter,
.rbc-header-gutter {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
}

.rbc-label {
    padding: 0 5px;
    font-size: 11px;

}

.rbc-day-slot {
    position: relative;
}
.rbc-day-slot .rbc-events-container {
    bottom: 0;
    left: 0;
    position: absolute;
    margin-right: 5px;
    right: 0;
    top: 0;
}
.rbc-day-slot .rbc-events-container.rbc-rtl {
    left: 10px;
    right: 0;
}
.rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event {
    border: 1px solid #929397;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-height: 100%;
    min-height: 15px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    overflow: hidden;
    position: absolute;
}
.rbc-day-slot .rbc-background-event {
    opacity: 0.75;
}
.rbc-day-slot .rbc-event-label {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    width: auto;
}
.rbc-day-slot .rbc-event-content {
    width: 100%;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0px;
    flex: 1 1 0;
    word-wrap: break-word;
    line-height: 1;
    height: 100%;
    min-height: 1em;
}
.rbc-day-slot .rbc-time-slot {
    border-top: 1px solid #f7f7f7;
}

.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
    position: sticky;
    left: 0;
    border-right: 1px solid #ddd;
    background-color: #fff;
    z-index: 10;
    margin-right: -1px;
}

.rbc-time-view-resources .rbc-time-header-gutter {
    background: #D4CACD;
    border-right: none;
}

.rbc-time-view-resources .rbc-time-header {
    overflow: hidden;
    background-color: #D4CACD;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
}

.rbc-time-view-resources .rbc-time-header-content {
    min-width: auto;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0px;
    flex: 1 0 0;
    -ms-flex-preferred-size: 0px;
    flex-basis: 0px;
    justify-content: center;
}
.rbc-time-view-resources .rbc-time-header-cell-single-day {
    display: none;
}
.rbc-time-view-resources .rbc-day-slot {
    min-width: 120px;
}
.rbc-time-view-resources .rbc-header,
.rbc-time-view-resources .rbc-day-bg {
    width: 120px;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0px;
    flex: 1 1 0;
    -ms-flex-preferred-size: 0 px;
    flex-basis: 0 px;
}

.rbc-time-header-content + .rbc-time-header-content {
    margin-left: -1px;
}

.rbc-time-slot {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0px;
    flex: 1 0 0;
    text-align: center;
}
.rbc-time-slot.rbc-now {
    font-weight: bold;
}

.rbc-day-header {
    text-align: center;
}

.rbc-slot-selection {
    z-index: 10;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 75%;
    width: 100%;
    padding: 3px;
}

.rbc-slot-selecting {
    cursor: move;
}

.rbc-time-view {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 100%;
    min-height: 0;
}
.rbc-time-view .rbc-time-gutter {
    white-space: nowrap;
    text-align: right;
}
.rbc-time-view .rbc-allday-cell {
    display: none;
}

.rbc-time-view .rbc-allday-events {
    position: relative;
    z-index: 4;
}
.rbc-time-view .rbc-row {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    min-height: 20px;
}

.rbc-time-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}
.rbc-time-header.rbc-overflowing {
    border-right: 1px solid #ddd;
}
.rbc-rtl .rbc-time-header.rbc-overflowing {
    border-right-width: 0;
    border-left: 1px solid #ddd;
}
.rbc-time-header > .rbc-row:first-child {
    border-bottom: 1px solid #ddd;
}
.rbc-time-header > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid #ddd;
}

.rbc-time-header-cell-single-day {
    display: none;
}

.rbc-time-header-content {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-width: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
.rbc-rtl .rbc-time-header-content {
    border-left-width: 0;
    border-right: 1px solid #ddd;
}
.rbc-time-header-content > .rbc-row.rbc-row-resource {
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.rbc-time-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    width: 100%;
    border-top: 2px solid #ddd;
    overflow-y: auto;
    position: relative;
}
.rbc-time-content > .rbc-time-gutter {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
}
.rbc-time-content > * + * > * {
    border-left: 1px solid #ddd;
}
.rbc-rtl .rbc-time-content > * + * > * {
    border-left-width: 0;
    border-right: 1px solid #ddd;
}
.rbc-time-content > .rbc-day-slot {
    width: 100%;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none;
}

.rbc-current-time-indicator {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #BB2649;
    pointer-events: none;
}
