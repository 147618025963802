.office-container {
    height: calc(100% - 100px);
    width: 100%;

    left: 0;
    top: 0;
    margin-top: 100px;
    position: fixed;
    background: white;
    z-index: 90;
}

.office-container .office-icon {
    position: absolute;
    right: 0;
    top: 13%;
}

.office-container .office-list {
    height: 85%;
    padding-left: 26px;
    padding-right: 26px;
    overflow-y: auto;

}

.office-container .button-footer {
    height: 15%;
    padding: 0 16px;
    align-items: center;
}

.office-container .city-office-content {
    width: fit-content;
}

.office-container .city-content {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;

}

.office-container .office-content {
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-transform: none;
    padding: 10px 20px;
}

.office-container .office-active {
    margin-left: 10px;
    color: #BB2649;
}
